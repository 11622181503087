.card-container {
    perspective: 1000px;   
}

.card-inner {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    transition: transform 0.3s ease-out;
    transform-style: preserve-3d;
}
  
.card-container:hover .card-inner{
    transition: 0.3s ease-out;
    transform: rotateY(180deg);
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.5);
}

.image-visiblity {
  backface-visibility: hidden;
  visibility: visible;
}
.card-container:hover .image-visiblity {
    visibility: hidden;
}

.buy-btn{
    visibility: hidden;
    height: fit-content;
    border-color: transparent;
    filter: grayscale(100%);
}
.card-container:hover .buy-btn{
    position: relative;
    visibility: visible;
    transform: rotateY(180deg);
    bottom: 75px;
}

.card-container:hover .card-content {
    visibility: visible;
    transform: rotateY(180deg);
}

.hot-badge{
    position: absolute;
    top: 10px;
    left: 10px;
}

.card-container:hover .badge {
    visibility: hidden;
}

@media  only screen and (max-width: 600px) {
    .buy-btn{
        visibility: visible;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .card-container:hover .card-inner{
        transform: none;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }
    .card-container:hover .image-visiblity {
        visibility: visible;
    }
    .card-container:hover .buy-btn{
        position: relative;
        visibility: visible;
        transform: rotateY(0deg);
        bottom: 0px;
    }
    .card-container:hover .card-content {
        transform: rotateY(0deg);
    }
    .card-container:hover .badge {
        visibility: visible;
    }
}

/*******************************/
/* Star Rating */
.star-rating {
    font-size: 2em;
    color: grey;
  }
  
  .star-rating span {
    display: inline-block;
    width: 1em;
    overflow: hidden;
  }
  
  .star-rating span.filled {
    color: gold;
  }